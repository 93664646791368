import React from "react"
import styled from "styled-components"
import GoogleWalletPassesIcon from "../assets/images/google_wallet_passes_button.svg"


const GoogleWalletPassesBlock = styled.div`
  display: grid;
  justify-items: center;
  margin-bottom: 2rem;
`
const GoogleWalletPassesButtonLink = styled.a`
  display: flex;
  align-items: center;
`

export default function GoogleWalletPasses(props) {
  return (
    <GoogleWalletPassesBlock>
      <GoogleWalletPassesButtonLink href={props.giftDetails.google_wallet_passes_token ? props.giftDetails.google_wallet_passes_token : ""}>
        <img src={GoogleWalletPassesIcon}></img>
      </GoogleWalletPassesButtonLink>
    </GoogleWalletPassesBlock>
  )
}